<template>
	<form class="container pb-5" @submit.prevent="register">
		<!-- <a-tutorial-video videoSrc='https://www.youtube.com/embed/UPeocNGQPNk'></a-tutorial-video> -->
		<div class="d-flex flex-wrap align-items-center w-100 justify-content-center mb-5">
			<div class="col-md-2 mb-3 mb-md-0 d-flex justify-content-center justify-content-md-start pl-md-0">
				<z-back-button @click.native="back" />
			</div>
			<div class="col-12 col-md-8">
				<h3 class="mb-2">Registrácia</h3>
				<p class="text-center">
					Máte už vytvorené konto?
					<router-link tag="strong" :to="{ name: 'Login' }" class="btn btn-success z-submit-btn rounded-pill ml-3 font-weight-bold">
						Prihláste sa
					</router-link>
				</p>
			</div>
			<div class="col-md-2"></div>
		</div>

		<div class="section mb-5">
			<!-- <span dev @click="DEV_prefill">prefill</span> -->
			<h5 class="mb-3">Zákazník</h5>

			<div class="row">
				<div class="col-lg-6">
					<w-input v-model="$v.form.name" label="Meno" chevron />
				</div>

				<div class="col-lg-6">
					<w-input v-model="$v.form.surname" label="Priezvisko" chevron />
				</div>

				<div class="col-lg-4">
					<w-input v-model="$v.form.street" label="Ulica a číslo domu" chevron />
				</div>

				<div class="col-lg-2">
					<w-input v-model="$v.form.zip" type="text" label="PSČ" chevron />
				</div>

				<div class="col-lg-6">
					<w-input v-model="$v.form.city" label="Mesto" chevron />
				</div>

				<div class="col-lg-6">
					<w-input v-model="$v.form.email" name="email" label="Email" chevron />
				</div>

				<div class="col-lg-6">
					<w-input v-model="$v.form.phone" label="Telefónne číslo" chevron />
				</div>

				<div class="col-lg-6">
					<w-input v-model="$v.form.password" label="Heslo" type="password" chevron />
				</div>

				<div class="col-lg-6">
					<w-input v-model="$v.confirmPassword" label="Potvrďte heslo" type="password" chevron />
				</div>
			</div>
		</div>

		<hr />

		<w-input tag="b-form-checkbox" v-model="$v.gdpr">
			<span>Súhlasím so <a target="_blank" href="https://hemisfera.sk/osobne-udaje">spracovaním mojich osobných údajov</a>.</span>
		</w-input>

		<b-button pill variant="success" type="submit" class="z-submit-btn float-right mb-5">Registrovať sa</b-button>
	</form>
</template>

<script>
import { mapState } from 'vuex'
import { required, email, sameAs, minLength, numeric } from 'vuelidate/lib/validators'

export default {
	components: {
		// 'a-tutorial-video': () => import('@/plugins/app/order/components/a-tutorial-video.vue')
	},
	data() {
		return {
			gdpr: false,
			confirmPassword: null,
			form: {
				email: localStorage.getItem('hemisfera_preregEmail'),
				password: null,
				name: null,
				surname: null,
				phone: null,
				street: null,
				zip: null,
				city: null
			}
		}
	},
	validations() {
		return {
			confirmPassword: {
				required,
				sameAsPassword: sameAs(function() {
					return this.form.password
				})
			},
			gdpr: {
				agreed(val) {
					return val
				}
			},

			form: {
				email: { required, email },
				password: { required, minLength: minLength(8) },
				name: { required },
				surname: { required },
				phone: { required },
				street: { required },
				zip: { required, numeric },
				city: { required }
			}
		}
	},

	mounted() {
		this._trackDataLayerEvent()
		window.scrollTo(0, 0)
	},

	computed: {
		...mapState('order', ['orderRegistrations'])
	},

	methods: {
		async register() {
			if (this.$v.$invalid) {
				this.$v.$touch()
				return
			}
			try {
				await this.$store.dispatch('wAuth/signup', this.form)
				await this.$store.dispatch('order/attachOrderToUser')

				localStorage.removeItem('hemisfera_preregEmail')
				this.$router.push({ name: 'StudentsInformation' })
			} catch (error) {
				this.$wToast.error(error)
			}
		},

		back() {
			this.$router.go(-1)
		},

		DEV_prefill() {
			this.confirmPassword = 'testing123'
			this.gdpr = true
			this.form = {
				email: 'testing@wezeo.com',
				password: 'testing123',
				name: 'testing_name',
				surname: 'testing_sur',
				phone: '0940555555',
				street: 'testing_street',
				zip: '999666',
				city: 'testing_city'
			}
			this.$v.$touch()
		},

		_trackDataLayerEvent() {
			window.dataLayer = window.dataLayer || []
			window.dataLayer.push({
				event: 'checkout_progress',
				pageTitle: 'Registrácia',
				product: this.orderRegistrations ? this.orderRegistrations[0].product_period.product : ''
			})
		}
	}
}
</script>

<style lang="sass" scoped>
strong
	cursor: pointer
</style>
